import React, { memo } from "react"
import { Helmet } from "react-helmet"

import header from "../images/header.png"
import memoji from "../images/memoji.png"

const Meta = () => {
    return( 
        <Helmet>
            <title>Aritro Paul | Portfolio</title>
            <link rel="shortcut icon" type="image/png" href={memoji}></link>
            <meta name="title" content="Aritro Paul | Portfolio"/>
            <meta name="description" content="My work over the last 4 years"/>
            <meta name="image" content={header}></meta>

            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://aritro.xyz"/>
            <meta property="og:title" content="Aritro Paul | Portfolio"/>
            <meta property="og:description" content="My work over the last 4 years"/>
            <meta property="og:image" content={header}/>

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content="https://aritro.xyz"/>
            <meta property="twitter:title" content="Aritro Paul | Portfolio"/>
            <meta property="twitter:description" content="My work over the last 4 years"/>
            <meta property="twitter:image" content={header}></meta>
        </Helmet>
    )
}

export default Meta