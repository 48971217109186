import React, { useCallback, useState } from 'react';
import {FiMoon, FiSun} from 'react-icons/fi'
import { getTheme, setPreferredTheme } from './darkmode';
import { IconContext } from 'react-icons';

const DarkModeToggle = () => {
  const [checked, setChecked] = useState(getTheme() == 'dark');
  const isLoaded = typeof window !== "undefined"
  setPreferredTheme(getTheme());

  function clicked(e) {
    const isChecked = checked
    if (isChecked) {
      setChecked(!isChecked);
      console.log(checked)
      setPreferredTheme('light');
    }
    else {
      setChecked(!isChecked);
      console.log(checked)
      setPreferredTheme('dark');
    }
  }


  if (!isLoaded) {
    return(
      <IconContext.Provider value={{ className: 'react-icons-toggle' }}>
        <button className="button-82-pushable" checked={checked} onClick={clicked} role="button">
          <span className="button-82-shadow"></span>
          <span className="button-82-edge"></span>
          <span className="button-82-front text">
            {/* { checked? <FiMoon></FiMoon>:<FiSun></FiSun> } */}
          </span>
        </button>
        {/* <button class="dark-toggle" checked={checked} onClick={clicked}> */}
        {/* </button> */}
      </IconContext.Provider>
    )
  }

  else {
    return(
      <IconContext.Provider value={{ className: 'react-icons-toggle' }}>
        <button className="button-82-pushable" checked={checked} onClick={clicked} role="button">
          <span className="button-82-shadow"></span>
          <span className="button-82-edge"></span>
          <span className="button-82-front">
            { checked? <FiMoon></FiMoon>:<FiSun></FiSun> }
          </span>
        </button>
        {/* <button class="dark-toggle" checked={checked} onClick={clicked}>
          
        </button> */}
      </IconContext.Provider>
    )
  }
};

export default DarkModeToggle;